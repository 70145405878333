<template>
  <div style="margin-top: 10px">
    <div style="display: flex; gap: 10px;">
      <b-form-group
        id="input-group-8"
        :label="$t('EditY')"
        label-for="input-25"
      >
        <vue-select
          v-model="year"
          :options="yearsArrays"
          style="width: 200px;"
          :clearable="false"
        />
      </b-form-group>
      <b-form-group
        id="input-group-8"
        :label="$t('Month')"
        label-for="input-25"
      >
        <vue-select
          v-model="month"
          :options="monthOptions"
          :reduce="(e) => e.value"
          label="name"
          style="width: 200px;"
          :clearable="false"
        />
      </b-form-group>
      <b-form-group
        id="input-select-1"
        :label="$t('SelectEmployee')"
        label-for="select-1"
        class="sales"
      >
        <vue-select
          v-model="salesUserId"
          :options="getUsersMultipleRoles"
          :reduce="(e) => e.userID"
          :placeholder="$t('Employees')"
          label="fullName"
          aria-describedby="input-1-live-feedback"
          :clearable="false"
        />
      </b-form-group>
      <PdfDocument
        :method-name="'soldItemsByUserPDF'"
        :getter-name="'getItemsSoldByUserPDF'"
        :pdf-name="'EmployeeSales'"
        :is-pagination="true"
        :header-name="header"
        :object-value="objectPDF"
        :start-date="month"
        :end-date="year"
        :person="selectedSalesPersonFullName"
        :pdf-title="$t('EmployeeSales')"
        style="margin-top: 1.3%;"
      />
      <button
        v-b-tooltip.hover="$t('DownloadExcel')"
        style="border: 0; width: 48px;
        height: 37px; background: #FF274F; border-radius: 5px; margin-top: 1.3%;"
        @click="downloadEXCEL"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="white"
          class="bi bi-file-earmark-spreadsheet"
          viewBox="0 0 16 16"
        >
          <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2zM9.5 3A1.5 1.5 0 0 0 11 4.5h2V9H3V2a1 1 0 0 1 1-1h5.5v2zM3 12v-2h2v2H3zm0 1h2v2H4a1 1 0 0 1-1-1v-1zm3 2v-2h3v2H6zm4 0v-2h3v1a1 1 0 0 1-1 1h-2zm3-3h-3v-2h3v2zm-7 0v-2h3v2H6z" />
        </svg>
      </button>
    </div>
    <div
      style="width: 100%;  padding-top: 16px; margin-top: 15px; height: 75vh; overflow-y: scroll;"
      class="scroll"
    >

      <table
        ref="exportable_table"
        class="team_table"
      >
        <thead style="position: sticky; top: 1px">
          <tr style="text-align: center;">
            <th>
              {{ $t('OrderNumber') }}
            </th>
            <th>
              {{ $t('ArticleNumber') }}
            </th>
            <th>
              {{ $t('Price') }}
            </th>
            <th>
              {{ $t('paidAmount') }}
            </th>
            <th>
              {{ $t('Discount') }}
            </th>
          </tr>
        </thead>
        <tbody v-if="getItemsSoldByUser.length > 0">
          <tr
            v-for="(items, index) in getItemsSoldByUser"
            :key="index"
            style="text-align: center;"
          >
            <td>
              {{ items.orderNumber }}
            </td>
            <td>
              {{ items.articleNumber }}
            </td>
            <td>
              {{ items.price }}€
            </td>
            <td>
              {{ items.paidAmount }}€
            </td>
            <td>
              {{ items.discount }}
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td
              colspan="5"
              style="padding: 11px 16px"
            >
              {{ $t('NoSales') }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex';
import PdfDocument from '@/components/PdfDocument/DownloadPDF.vue'
import FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { client } from '../domainConfig'

export default {
  components: {
    PdfDocument,
  },
  data() {
    return {
      month: moment().month() + 1,
      year: moment().year(),
      client: client.clientName,
      salesUserId: null,
      pageNumber: 1,
      pageSize: 15,
      status: true,
      userID: null,
      header: [
        {
          orderNumber: this.$t('OrderNumber'),
          articleNumber: this.$t('articleNumber'),
          price: this.$t('Price'),
          paidAmount: this.$t('paidAmount'),
          discount: this.$t('Discount'),
          remainingPrice: this.$t('RemainingAmount'),
        },
      ],
      // objectPDF: {
      //   month: this.month,
      //   year: this.year,
      //   salesUserId: this.salesUserId,
      //   pageNumber: this.pageNumber,
      //   pageSize: this.pageSize,
      // },
      monthOptions: [
        {
          value: 1,
          name: this.$t('January'),
        },
        {
          value: 2,
          name: this.$t('February'),
        },
        {
          value: 3,
          name: this.$t('March'),
        },
        {
          value: 4,
          name: this.$t('April'),
        },
        {
          value: 5,
          name: this.$t('May'),
        },
        {
          value: 6,
          name: this.$t('June'),
        },
        {
          value: 7,
          name: this.$t('July'),
        },
        {
          value: 8,
          name: this.$t('August'),
        },
        {
          value: 9,
          name: this.$t('September'),
        },
        {
          value: 10,
          name: this.$t('October'),
        },
        {
          value: 11,
          name: this.$t('November'),
        },
        {
          value: 12,
          name: this.$t('December'),
        },
      ],
    }
  },
  computed: {
    ...mapGetters([
      'getItemsSoldByUser',
      'getUsersMultipleRoles',
      'getUserRole',
      'getLoggedInUser',
      'getItemsSoldByUserPDF',
    ]),
    objectPDF() {
      return {
        month: this.month,
        year: this.year,
        salesUserId: this.salesUserId,
        pageNumber: this.pageNumber,
        pageSize: 50,
      }
    },
    yearsArrays() {
      const result = [];
      const baseYear = this.year || moment().year();

      for (let i = -3; i < 4; i++) {
        result.push(baseYear + i);
      }

      return result;
    },
    selectedSalesPersonFullName() {
      const selectedUser = this.getUsersMultipleRoles.find((user) => { return user.userID === this.salesUserId });
      return selectedUser ? selectedUser.fullName : '';
    },
  },
  watch: {
    month(newValue) {
      if (newValue && this.year && this.salesUserId) {
        this.callSalesByUser();
      }
    },
    year(newValue) {
      if (newValue && this.month && this.salesUserId) {
        this.callSalesByUser()
      }
    },
    salesUserId(newValue) {
      if (newValue && this.month && this.year) {
        this.callSalesByUser()
      }
    },
  },
  mounted() {
    this.usersByMultipleRoles({
      status: this.status,
      object: ['SalesPerson', 'OnlineSales'],
    })
    this.getUsersRole()
  },
  methods: {
    ...mapActions([
      'soldItemsByUser',
      'usersByMultipleRoles',
      'getUsersRole',
      'soldItemsByUserPDF',
    ]),
    callSalesByUser() {
      this.soldItemsByUser({
        month: this.month,
        year: this.year,
        salesUserId: this.salesUserId,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      })
    },
    generateTableContentCSV(data) {
      const headers = [this.$t('OrderNumber'), this.$t('ArticleNumber'), this.$t('Price'), this.$t('paidAmount'), this.$t('RemainingAmount')];
      const body = [];

      data.forEach((item) => {
        const remainingPrice = item.price - item.paidAmount;

        body.push([
          item.orderNumber,
          item.articleNumber,
          item.price,
          item.paidAmount,
          remainingPrice,
        ]);
      });

      return { headers, body };
    },
    generateExcel(tableContent) {
      const { headers, body } = tableContent;
      const sheetData = [headers, ...body];

      const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

      const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      const excelData = new Blob([excelBuffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      return excelData;
    },

    downloadEXCEL() {
      this.soldItemsByUser({
        month: this.month,
        year: this.year,
        salesUserId: this.salesUserId,
        pageNumber: this.pageNumber,
        pageSize: 50,
      }).then(() => {
        const tableContent = this.generateTableContentCSV(this.getItemsSoldByUser);

        const totalPages = this.getItemsSoldByUser;
        const additionalPromises = [];
        for (let pageNumber = 2; pageNumber <= totalPages; pageNumber++) {
          additionalPromises.push(this.downloadPage(pageNumber));
        }

        Promise.all(additionalPromises).then((additionalTableContents) => {
          additionalTableContents.forEach((additionalTableContent) => {
            tableContent.body = tableContent.body.concat(additionalTableContent.body);
          });

          const excelData = this.generateExcel(tableContent);
          FileSaver.saveAs(excelData, 'EmployeeSales.xlsx');
        });
      });
    },
  },
}
</script>

<style scoped>
.sales {
  width: 12%;
}
.team_table td {
  padding: 16px!important;
}
@media screen and (max-width: 1200px) {
  .sales {
    width: 21%;
  }
}
</style>
